.contenedor-header {
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ocupa la altura completa de la ventana */
  text-align: center;
}

.contenedor-header header {
  padding-top: 5em;
}

.contenedor-header header h1 {
  color: #0b532b;
  font-family: "Eyesome Script";
  text-align: center;
  align-content: center;
  margin: 0 auto;
  margin-bottom: 0 auto; /* Elimina el espacio entre los elementos h1 */
  margin-top: 0;
  font-weight: 60;
  font-size: 70px;
  line-height: 100px;
}

.contenedor-header h2 {
  font-family: "Truculenta";
  color: #0b532b;
  padding-top: 1.9em;
  margin-top: 10 auto;
  margin-bottom: 0; /* Elimina el espacio entre los elementos h1 */
  font-size: 32px;
  font-weight: 400;
}

@media (max-aspect-ratio: 30/23) {
  .contenedor-header {
    height: 100vh;
    background-color: #f2f2f2;
    background-size: auto 80%;
    background-position: 61% 0;
    background-repeat: no-repeat;
    background-origin: content-box; /* Establece el origen del fondo en el borde del contenido */
    padding-bottom: 0; /* Elimina el padding que se agrega debajo del contenedor */
    /*margin-bottom: -5rem;*/
  }
  .contenedor-header header {
    padding-top: 1.5em;
  }
  .contenedor-header header h1 {
    font-size: 60px;
    line-height: 5rem;
  }
}
