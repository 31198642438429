@import url("https://fonts.googleapis.com/css2?family=Pompiere&display=swap");

@font-face {
  font-family: fg;
  font-weight: 300;
  font-style: normal;
  src: url("./fonts/foundersGrotesk/FoundersGrotesk-Light.woff2")
      format("woff2"),
    url("./fonts/foundersGrotesk/FoundersGrotesk-Light.woff") format("woff");
}
@font-face {
  font-family: fg;
  font-weight: 500;
  font-style: normal;
  src: url("./fonts/foundersGrotesk/FoundersGrotesk-Medium.woff2")
      format("woff2"),
    url("./fonts/foundersGrotesk/FoundersGrotesk-Medium.woff") format("woff");
}

@font-face {
  font-family: "Eyesome Script";
  src: url("./fonts//eyesome/Eyesome_Script.woff2") format("woff2"),
    url("./fonts/eyesome/Eyesome_Script.woff") format("woff");
}

@font-face {
  font-family: c;
  font-weight: 500;
  font-style: normal;
  src: url("./fonts/canela/Canela-Medium.woff2") format("woff2"),
    url("./fonts/canela/Canela-Medium.woff") format("woff");
}
@font-face {
  font-family: c;
  font-weight: 700;
  font-style: normal;
  src: url("./fonts/canela/Canela-Bold.woff2") format("woff2"),
    url("./fonts/canela/Canela-Bold.woff") format("woff");
}

@import url("https://fonts.googleapis.com/css2?family=Pompiere&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Truculenta:opsz,wght@12..72,100..900&display=swap");

body {
  margin: 0;
  font-family: fg, canela, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 2rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
